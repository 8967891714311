<template>
  <section class="section-base" :class="classAdd">
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    classAdd: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
